import React, { useCallback, useEffect, useReducer, useState } from 'react';
import './App.css';
import ProtectedRoute, { OnlyAnonymousRoute } from './ProtectedRoute';
import jwt_decode from 'jwt-decode';
import { Login } from './Login';
import { SesionesActivas } from './Paginas/SesionesActivas';
import { Home } from './Paginas/Home';
import tokenStorage from './TokenStorage';
import { createBrowserHistory } from 'history';
import { Button, Container, Form, Modal, Nav, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Router, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { MyModal } from './MyModal'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import RateLimit from 'RateLimit';
import MyErrorBoundary from 'MyErrorBoundary';
import Axios from 'axios';

export class SesionPerdidaError extends Error {
  innerError: Error | undefined;
  constructor(message?: string, innerError?: Error) {
    // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
    super(message);
    // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SesionPerdidaError)
    }

    this.name = 'SesionPerdidaError';
    this.innerError = innerError;
  }
}

function getUrlMongo() {
  if (process.env.NODE_ENV === 'development') {
    return 'https://localhost:44304/api';
    // return 'https://localhost:30001/api';
  } else if (process.env.REACT_APP_BUILD === 'dev') {
    // return 'http://192.168.2.90/apimongosintia/api';
    return 'https://test.api.sintia.com.ar/api';
  } else {
    return 'https://api.sintia.com.ar/mongo/api';
  }
}

export const urlMongo = getUrlMongo();
export const urlMantenimiento = 'https://api.sintia.com.ar/mongoMant/api';

//const basename = process.env.NODE_ENV === 'development' ? undefined : '/websintiareboot';
const basename = undefined;

export const mantenimientoClient = Axios.create({
  baseURL: urlMantenimiento
});
export const loginClient = Axios.create({
  baseURL: urlMongo
});
const client = Axios.create({
  baseURL: urlMongo
});
const rateLimit = new RateLimit(6);
client.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + tokenStorage.getAccessToken()
  };
  return rateLimit.delayConfig(config);
}, error => error);
client.interceptors.response.use(success => success, async error => {
  try {
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error);
    }
    let respuesta = await loginClient.post('/Account/Refresh', {
      AccessToken: tokenStorage.getAccessToken(),
      RefreshToken: tokenStorage.getRefreshToken()
    });
    tokenStorage.setAccessToken(respuesta.data.AccessToken);
    tokenStorage.setRefreshToken(respuesta.data.RefreshToken);
    return client.request(error.config);
  } catch (error2) {
    //todo: ver como avisar al usuario de un error
    console.log('Error al refrescar la sesion', JSON.stringify(error2));
    appContextState.cerrarSesion();
    browserHistory.replace('/login');
    appContextState.mostrarError('Se ha perdido la sesión. Vuelva a iniciar sesión');
    return Promise.reject(new SesionPerdidaError('Error al refrescar la sesion', error2));
  }
});

export const userClient = client;

export const AppContext = React.createContext({
  userInfo: { sesionIniciada: false } as UserInfo,
  iniciarSesion: (token: string) => { },
  cerrarSesion: () => { },
  // setEmpresaActual: (empresa: string) => { },
  mostrarError: (error: string) => { }
});

export interface UserInfo {
  sesionIniciada: boolean,
  nroClienteAlpha?: number,
  // empresaActual?: string,
  claims?: any
}

interface AppContextState {
  userInfo: UserInfo,
  iniciarSesion: (token: string) => void,
  cerrarSesion: () => void,
  // setEmpresaActual: (empresa: string) => void,
  mostrarError: (error: string) => void,
}

function AppReducer(userInfo: UserInfo, action: any) {
  if (action.type === 'cerrarSesion') {
    return { sesionIniciada: false };
  } else if (action.type === 'iniciarSesion') {
    return { sesionIniciada: true, claims: action.claims, nroClienteAlpha: action.claims.NroClienteAlpha };
  } else {
    throw new Error('Accion desconocida');
  }
}
let appContextState: AppContextState;
let browserHistory = createBrowserHistory({ basename: basename });

function App() {
  let [userInfo, updateUserInfo] = useReducer(AppReducer, { sesionIniciada: false });
  let [mensajeErrorModal, updateMensajeErrorModal] = useState('');
  let [bloquearApp, updateBloquearApp] = useState(false);
  const mostrarError = useCallback((error: string) => updateMensajeErrorModal(error), []);
  appContextState = {
    userInfo: userInfo, iniciarSesion: (accessToken: string) => updateUserInfo({ type: 'iniciarSesion', claims: jwt_decode(accessToken) }),
    cerrarSesion: () => {
      tokenStorage.setAccessToken('');
      tokenStorage.setRefreshToken('');
      updateUserInfo({ type: 'cerrarSesion' });
      //browserHistory.replace('/login');
    },
    mostrarError: mostrarError,
    // setEmpresaActual: (empresa: string) => updateUserInfo({ type: 'setEmpresaActual', empresa: empresa })
  };
  useEffect(() => {
    let accessToken = tokenStorage.getAccessToken();
    if (accessToken) {
      appContextState.iniciarSesion(accessToken);
    }
  }, []);
  return (<MyErrorBoundary>
    <AppContext.Provider value={appContextState}>
      <MyModal show={!!mensajeErrorModal} onHide={() => updateMensajeErrorModal('')}>
        <Modal.Dialog>
          <Modal.Body>
            <p className="text-danger lead">{mensajeErrorModal}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => updateMensajeErrorModal('')}>Cerrar</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </MyModal>
      <BlockUi blocking={bloquearApp ? true : undefined}>
        <Router history={browserHistory}>
          <Navbar bg="dark" variant="dark" id="navbar">
            <Navbar.Toggle aria-controls="navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id="navbar-nav">
              <Nav className="mr-auto">
                {userInfo.sesionIniciada && <>
                  <Nav.Link as={Link} to="/sesionesActivas">Sesiones Activas</Nav.Link>
                </>}
              </Nav>
            </Navbar.Collapse>
            {/* {userInfo.empresaActual && <Nav>
              <Nav.Link as={Link} to="/selectEmpresa">Empresa: {nombreEmpresa}</Nav.Link>
            </Nav>} */}
            {
              userInfo.sesionIniciada &&
              (<Form inline onSubmit={async e => {
                e.preventDefault();
                try {
                  updateBloquearApp(true);
                  await client.post('/Account/LogOut');
                } catch (error) {
                  console.error('Error al cerrar sesión', error);
                } finally {
                  updateBloquearApp(false);
                }
                appContextState.cerrarSesion();
              }}>
                <Button className="ml-2" type="submit" variant="warning">Cerrar sesión</Button>
              </Form>)
            }
          </Navbar>
          <Container fluid className="mt-2">
            <div>
              <MyErrorBoundary>
                <Switch>
                  {/* <ProtectedRoute path="/selectEmpresa" component={SelectEmpresa}></ProtectedRoute> */}
                  <ProtectedRoute exact path="/sesionesActivas" allowRoles={['Administrador']} component={SesionesActivas}></ProtectedRoute>
                  <OnlyAnonymousRoute path="/login" component={Login}></OnlyAnonymousRoute>
                  <ProtectedRoute exact path="/" component={Home}></ProtectedRoute>
                  <Route path="*" component={NotFoundComponent}></Route>
                </Switch>
              </MyErrorBoundary>
            </div>
          </Container>
        </Router>
      </BlockUi>
    </AppContext.Provider>
  </MyErrorBoundary>
  );
}

export default App;

export function NotFoundComponent() {
  return (<div>
    <p className="lead">No se encuentra la página</p>
  </div>);
}

export function NotAuthorizedComponent() {
  return (<div>
    <p className="lead text-danger">No está autorizado a ver esta página</p>
  </div>)
}