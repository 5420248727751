import { CancelToken } from "axios";
import React, { useState, useReducer, useRef, useEffect, useContext } from "react";
import { useCancel, createQueryString } from "Utilidades";
import { AppContext, userClient } from "App";
import Grilla, { GrillaRef, TipoCampo, GrillaSync } from "../Grilla";
import { Form, Button, Modal } from "react-bootstrap";
import BlockUi from "react-block-ui";
import { MyModal } from "MyModal";

enum EstadoModal {
    Cerrado,
    CargandoLocks,
    Cargando,
    Abierto
}

enum TipoLock {
    Caratula = 0,
    CaratulaProcesoExcel,
    Catalogo,
    Importador,
    Despachante,
    Vendedor,
    ReglaValidacionMaria
}

const plantillaFormatoTipoLock = (tipo: any) => {
    switch (tipo) {
        case TipoLock.Caratula:
            return 'Caratula';
        case TipoLock.CaratulaProcesoExcel:
            return 'Caratula Proceso Excel';
        case TipoLock.Catalogo:
            return 'Catalogo';
        case TipoLock.Despachante:
            return 'Despachante';
        case TipoLock.Importador:
            return 'Importador';
        case TipoLock.ReglaValidacionMaria:
            return 'Regla Validación María';
        case TipoLock.Vendedor:
            return 'Vendedor';
        default:
            return '';
    }
}

export function SesionesActivas() {
    const { mostrarError } = useContext(AppContext);
    const { getCancelToken, isCancel } = useCancel();
    const [busqueda, updateBusqueda] = useReducer((estado: any, accion: any) => {
        return { ...estado, [accion.tipo]: accion.valor };
    }, { nombreUsuario: '', nroClienteAlpha: null, mostrarWebService: false });
    const [busquedaActual, updateBusquedaActual] = useState({
        nombreUsuario: '', nroClienteAlpha: null, mostrarWebService: false,
        contador: 0
    });
    let refGrilla = useRef<GrillaRef>(null);
    useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    const [cargando, updateCargando] = useState(false);
    const [modal, updateModal] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModal.Cerrado, session: null, locks: [] };
        } else if (accion.tipo === 'mostrar') {
            return { estadoModal: EstadoModal.CargandoLocks, session: accion.session, locks: [] };
        } else if (accion.tipo === 'setLocks') {
            return { ...estado, estadoModal: EstadoModal.Abierto, locks: accion.locks ?? [] };
        } else if (accion.tipo === 'deleteLock') {
            return { ...estado, estadoModal: EstadoModal.Abierto, locks: estado.locks.filter((l: any) => l.Id !== accion.id) };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, estadoModal: accion.valor ? EstadoModal.Cargando : EstadoModal.Abierto };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, session: null, locks: [] });
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/Admin/SesionesActivas'
            + createQueryString({
                NombreUsuario: busquedaActual.nombreUsuario,
                NroClienteAlpha: busquedaActual.nroClienteAlpha,
                MostrarWebService: busquedaActual.mostrarWebService,
                Desde: desde, Hasta: hasta
            }), {
            cancelToken: cancelToken
        });
        return {
            cantidadItems: respuesta.data.CantidadTotal,
            items: respuesta.data.Items
        };
    }
    async function cerrarSesionDeUsuario(item: any) {
        updateCargando(true);
        try {
            await userClient.post('/Admin/CerrarSesionUsuario', {
                NroClienteAlpha: item.NroClienteAlpha,
                UsuarioId: item.UsuarioId,
                SessionId: item.SessionId
            }, { cancelToken: getCancelToken() });
            refGrilla.current?.recargar();
            updateCargando(false);
        } catch (error) {
            if (!isCancel(error)) {
                console.error('Error al cerrar sesión de usuario', error);
                mostrarError('Error al cerrar sesión de usuario');
                updateCargando(false);
            }
        }
    }
    async function borrarLock(item: any) {
        updateModal({ tipo: 'setCargando', valor: true });
        try {
            await userClient.post('/Admin/BorrarLock', {
                NroClienteAlpha: item.NroClienteAlpha,
                LockId: item.Id
            }, { cancelToken: getCancelToken() });
            updateModal({ tipo: 'deleteLock', id: item.Id });
        } catch (error) {
            if (!isCancel(error)) {
                console.error('Error al borrar candado', error);
                mostrarError('Error al borrar candado');
                updateModal({ tipo: 'setCargando', valor: false });
            }
        }
    }
    const campos = [{ propiedad: 'SessionId', visible: false, clave: true },
    { propiedad: 'NombreUsuario', titulo: 'Nombre Usuario' },
    { propiedad: 'NroClienteAlpha', titulo: 'Nro. Cliente Alpha' },
    { propiedad: 'FechaInicioSesion', titulo: 'Fecha Inicio Sesión', tipo: TipoCampo.DateTime },
    { propiedad: 'IpUsuario', titulo: 'Ip' }, {
        propiedad: 'Tipo', titulo: 'Tipo', plantillaFormato: (tipo: any) => {
            switch (tipo) {
                case 0:
                default:
                    return 'Web Service';
                case 1:
                    return 'Escritorio';
                case 2:
                    return 'Web';
            }
        }
    }];
    const camposModal = [{ propiedad: 'Id', visible: false, clave: true },
    { propiedad: 'EmpresaId', titulo: 'Cuit Empresa' },
    { propiedad: 'Tipo', titulo: 'Tipo', plantillaFormato: plantillaFormatoTipoLock },
    { propiedad: 'Nombre', titulo: 'Nombre' }, { propiedad: 'Nombre2', titulo: 'Nombre 2' },
    { propiedad: 'FechaModificacion', titulo: 'Fecha Modificación', tipo: TipoCampo.DateTime }];
    useEffect(() => {
        async function cargarLocks() {
            try {
                let respuesta = await userClient.get('/Admin/LocksActivosDeUsuario' + createQueryString({
                    NroClienteAlpha: modal.session.NroClienteAlpha,
                    SessionId: modal.session.SessionId
                }), { cancelToken: getCancelToken() });
                updateModal({ tipo: 'setLocks', locks: respuesta.data });
            } catch (error) {
                if (!isCancel(error)) {
                    console.error('Error al cargar candados', error);
                    mostrarError('Error al cargar candados');
                    updateModal({ tipo: 'cerrar' });
                }
            }
        }
        if (modal.estadoModal === EstadoModal.CargandoLocks) {
            cargarLocks();
        }
        //eslint-disable-next-line
    }, [modal.estadoModal]);
    return (<>
        <h2>Sesiones Activas</h2>
        <BlockUi blocking={cargando}>
            <Form inline onSubmit={e => {
                updateBusquedaActual(estadoAnt => ({
                    ...busqueda, contador: estadoAnt.contador + 1
                }));
                e.preventDefault();
            }}>
                <Form.Group controlId="txtNombreUsuario" className="mb-2 mr-2">
                    <Form.Label className="mr-2">Nombre usuario</Form.Label>
                    <Form.Control type="text" value={busqueda.nombreUsuario} onChange={e => updateBusqueda({ tipo: 'nombreUsuario', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Form.Group controlId="txtNroClienteAlpha" className="mb-2 mr-2">
                    <Form.Label className="mr-2">Nro Cliente Alpha</Form.Label>
                    <Form.Control type="number" step={1} value={busqueda.nroClienteAlpha} onChange={e => updateBusqueda({ tipo: 'nroClienteAlpha', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Check className="mr-2" id="checkMostrarWebService" label="Mostrar Web Service" custom value={busqueda.mostrarWebService} onChange={e => updateBusqueda({ tipo: 'mostrarWebService', valor: e.target.checked })}></Form.Check>
                </Form.Group>
                <Button type="submit" className="mb-2">Buscar</Button>
            </Form>
            <Grilla ref={refGrilla} campos={campos} cargarDatos={cargarDatos}
                eventoDetalle={(item: any) => updateModal({ tipo: 'mostrar', session: item })}
                eventoEliminar={cerrarSesionDeUsuario}></Grilla>
        </BlockUi>
        <MyModal show={modal.estadoModal !== EstadoModal.Cerrado} onHide={() => updateModal({ tipo: 'cerrar' })}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Candados de {modal.session?.NombreUsuario}</h2>
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={modal.estadoModal !== EstadoModal.Abierto}>
                        <GrillaSync datos={modal.locks} campos={camposModal} eventoEliminar={borrarLock}></GrillaSync>
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateModal({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>)
}