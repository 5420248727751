import { useField, connect, useFormikContext } from "formik";
import { Form, InputGroup, Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from 'react';
import BlockUi from "react-block-ui";
// import AsyncSelect from "react-select/async";
// import AsyncCreatableSelect from "react-select/async-creatable";
// import styled from "styled-components";
import { spliceString } from "./Utilidades";
// import WindowedSelect from "react-windowed-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import flatten from "flat";
import InputMask from "react-input-mask";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// type AllowedSelectValues = string | number | string[];

type FormContextType = {
    inline: boolean,
    setAutoFocusElement?: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

let FormContext = React.createContext({ inline: false } as FormContextType);

export const MyFormControl = React.forwardRef((props: {
    id?: string, name: string, label?: string, hideLabel?: boolean, readOnly?: boolean, autoFocus?: boolean,
    onValueChange?: (value: any) => void, onValueChangeEnHook?: boolean,
    onKeyDown?: React.KeyboardEventHandler<any>, plaintext?: boolean,
    type: 'text' | 'number' | 'password' | 'date' | 'email',
    onBlur?: React.FocusEventHandler<any>, disabled?: boolean, placeholder?: string,
    maxLength?: number, style?: React.CSSProperties
}, ref: React.Ref<HTMLElement>) => {
    let [inputElement, updateInputElement] = useState<HTMLElement | null>(null);
    let [field, meta] = useField(props.name);
    let formik = useFormikContext();
    let { onValueChange, onValueChangeEnHook } = props;
    //llama el evento onValueChange siempre que cambie el valor del campo por cualquier motivo
    //en caso que la propiedad onValueChangeEnHook este activa
    useEffect(() => {
        if (onValueChange && onValueChangeEnHook) {
            onValueChange(field.value);
        }
    }, [field.value, onValueChange, onValueChangeEnHook]);
    let formContext = React.useContext(FormContext);
    useEffect(() => {
        if (props.autoFocus && inputElement) {
            formContext.setAutoFocusElement?.call(null, inputElement);
        }
        //eslint-disable-next-line
    }, [inputElement]);
    let id = props.id ?? field.name;
    return (<>
        <Form.Label htmlFor={id} srOnly={props.hideLabel}>{props.label}</Form.Label>
        <Form.Control type={props.type === 'number' ? 'text' : props.type} name={field.name}
            onKeyDown={props.onKeyDown} disabled={props.disabled} ref={(input: any) => {
                updateInputElement(input);
                if (typeof ref === 'function') {
                    ref(input);
                } else if (ref) {
                    let mutableRef = ref as React.MutableRefObject<HTMLElement | null>;
                    mutableRef.current = input;
                }
            }} value={field.value ?? ''} onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (props.type === 'number' && !e.ctrlKey) {
                    let target = e.target as HTMLInputElement;
                    if (e.key === ',') {
                        e.preventDefault();
                        if (!target.value.includes('.')) {
                            target.value += '.';
                        }
                    } else {
                        let allowedChars = '0123456789.';
                        let invalidKey = (e.key.length === 1 && !allowedChars.includes(e.key))
                            || (e.key === '.' && target.value.includes('.'));
                        invalidKey && e.preventDefault();
                    }

                }
            }} onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                if (props.type === 'number') {
                    let text = e.clipboardData.getData('text');
                    text = text.replace(/[^0-9.]/g, '');
                    let element = e.target as HTMLInputElement;
                    if (element.selectionStart !== null && element.selectionEnd !== null) {
                        text = spliceString(element.value, element.selectionStart, text, element.selectionEnd - element.selectionStart);
                    }
                    let indicePunto = text.indexOf('.');
                    if (indicePunto > -1) {
                        text = text.substring(0, indicePunto + 1) + text.substring(indicePunto + 1).replace(/\./g, '');
                    }
                    formik.setFieldValue(field.name, text);
                    e.preventDefault();
                }
            }} plaintext={props.plaintext} className={props.type === 'number' ? 'text-right' : ''}
            onChange={e => {
                field.onChange(e);
                if (!props.onValueChangeEnHook) {
                    props.onValueChange?.call(undefined, e.target.value);
                }
            }} onBlur={(e: React.FocusEvent<any>) => {
                formik.setFieldValue(field.name, e.target.value.trim());
                props.onBlur?.call(undefined, e);
            }} placeholder={props.placeholder} maxLength={props.maxLength}
            isInvalid={meta.touched && !!meta.error} id={id} readOnly={props.readOnly} style={props.style}>
        </Form.Control>
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>);
});

export const MyTextarea = React.forwardRef((props: {
    id?: string, name: string, label?: string, hideLabel?: boolean, readOnly?: boolean, autoFocus?: boolean,
    onValueChange?: (value: any) => void, onValueChangeEnHook?: boolean,
    onKeyDown?: React.KeyboardEventHandler<any>, plaintext?: boolean,
    onBlur?: React.FocusEventHandler<any>, disabled?: boolean, placeholder?: string,
    maxLength?: number, style?: React.CSSProperties, rows?: number
}, ref: React.Ref<HTMLElement>) => {
    let [inputElement, updateInputElement] = useState<HTMLElement | null>(null);
    let [field, meta] = useField(props.name);
    let formik = useFormikContext();
    let { onValueChange, onValueChangeEnHook } = props;
    //llama el evento onValueChange siempre que cambie el valor del campo por cualquier motivo
    //en caso que la propiedad onValueChangeEnHook este activa
    useEffect(() => {
        if (onValueChange && onValueChangeEnHook) {
            onValueChange(field.value);
        }
    }, [field.value, onValueChange, onValueChangeEnHook]);
    let formContext = React.useContext(FormContext);
    useEffect(() => {
        if (props.autoFocus && inputElement) {
            formContext.setAutoFocusElement?.call(null, inputElement);
        }
        //eslint-disable-next-line
    }, [inputElement]);
    let id = props.id ?? field.name;
    return (<>
        <Form.Label htmlFor={id} srOnly={props.hideLabel}>{props.label}</Form.Label>
        <Form.Control as="textarea" name={field.name}
            onKeyDown={props.onKeyDown} disabled={props.disabled} ref={(input: any) => {
                updateInputElement(input);
                if (typeof ref === 'function') {
                    ref(input);
                } else if (ref) {
                    let mutableRef = ref as React.MutableRefObject<HTMLElement | null>;
                    mutableRef.current = input;
                }
            }} value={field.value ?? ''} plaintext={props.plaintext}
            onChange={e => {
                field.onChange(e);
                if (!props.onValueChangeEnHook) {
                    props.onValueChange?.call(undefined, e.target.value);
                }
            }} onBlur={(e: React.FocusEvent<any>) => {
                formik.setFieldValue(field.name, e.target.value.trim());
                props.onBlur?.call(undefined, e);
            }} placeholder={props.placeholder} maxLength={props.maxLength} rows={props.rows}
            isInvalid={meta.touched && !!meta.error} id={id} readOnly={props.readOnly} style={props.style}>
        </Form.Control>
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>);
});

export const MyMaskedFormControl = React.forwardRef((props: {
    id?: string, name: string, label?: string, hideLabel?: boolean, readOnly?: boolean, autoFocus?: boolean,
    onValueChange?: (value: any) => void, onValueChangeEnHook?: boolean,
    onKeyDown?: React.KeyboardEventHandler<any>, plaintext?: boolean,
    onBlur?: React.FocusEventHandler<any>, disabled?: boolean, placeholder?: string,
    mask: string
}, ref: React.Ref<HTMLElement>) => {
    let [inputElement, updateInputElement] = useState<HTMLElement | null>(null);
    let [field, meta] = useField(props.name);
    let formik = useFormikContext();
    let { onValueChange, onValueChangeEnHook } = props;
    //llama el evento onValueChange siempre que cambie el valor del campo por cualquier motivo
    //en caso que la propiedad onValueChangeEnHook este activa
    useEffect(() => {
        if (onValueChange && onValueChangeEnHook) {
            onValueChange(field.value);
        }
    }, [field.value, onValueChange, onValueChangeEnHook]);
    let formContext = React.useContext(FormContext);
    useEffect(() => {
        if (props.autoFocus && inputElement) {
            formContext.setAutoFocusElement?.call(null, inputElement);
        }
        //eslint-disable-next-line
    }, [inputElement]);
    let id = props.id ?? field.name;
    return (<>
        <Form.Label htmlFor={id} srOnly={props.hideLabel}>{props.label}</Form.Label>
        <InputMask mask={props.mask} onChange={(e: any) => {
            field.onChange(e);
            if (!props.onValueChangeEnHook) {
                props.onValueChange?.call(undefined, e.target.value);
            }
        }} onBlur={(e: React.FocusEvent<any>) => {
            formik.setFieldValue(field.name, e.target.value.trim());
            props.onBlur?.call(undefined, e);
        }} disabled={props.disabled} value={field.value ?? ''}>
            {() => <Form.Control type="text" name={field.name}
                onKeyDown={props.onKeyDown} plaintext={props.plaintext}
                isInvalid={meta.touched && !!meta.error} id={id}
                ref={(input: any) => {
                    updateInputElement(input);
                    if (typeof ref === 'function') {
                        ref(input);
                    } else if (ref) {
                        let mutableRef = ref as React.MutableRefObject<HTMLElement | null>;
                        mutableRef.current = input;
                    }
                }} placeholder={props.placeholder}>
            </Form.Control>}
        </InputMask>
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>);
});

export function MyFormControlWithIcon
    (props: {
        id?: string, name: string, icon?: IconProp, label?: string, readOnly?: boolean,
        onKeyDown?: React.KeyboardEventHandler<any>,
        type: 'text' | 'number' | 'password' | 'date' | 'email' | 'textarea',
        placeholder?: string
    }) {
    let [field, meta] = useField(props.name);
    let formik = useFormikContext();
    //let formContext = React.useContext(FormContext);
    let id = props.id ?? field.name;
    return (<>
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>{props.icon ? (<FontAwesomeIcon color="#216aeb" icon={props.icon}></FontAwesomeIcon>) : props.label}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Label htmlFor={id} srOnly>{props.label}</Form.Label>
            <Form.Control type={props.type === 'number' ? 'text' : props.type} name={field.name}
                onKeyDown={props.onKeyDown}
                value={field.value ?? ''} onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (props.type === 'number' && !e.ctrlKey) {
                        let target = e.target as HTMLInputElement;
                        if (e.key === ',') {
                            e.preventDefault();
                            if (!target.value.includes('.')) {
                                target.value += '.';
                            }
                        } else {
                            let allowedChars = '0123456789.';
                            let invalidKey = (e.key.length === 1 && !allowedChars.includes(e.key))
                                || (e.key === '.' && target.value.includes('.'));
                            invalidKey && e.preventDefault();
                        }

                    }
                }}
                onChange={field.onChange} onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    formik.setFieldValue(field.name, e.target.value.trim());
                }} placeholder={props.placeholder}
                isInvalid={meta.touched && !!meta.error} id={id} readOnly={props.readOnly}>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        </InputGroup>
    </>);
}

export function LoginPasswordControl
    (props: {
        id?: string, name: string, label?: string, readOnly?: boolean,
        onKeyDown?: React.KeyboardEventHandler<any>,
        placeholder?: string
    }) {
    let [buttonBorderRadius, setButtonBorderRadius] = useState<any>(null);
    let [showPassword, setShowPassword] = useState(false);
    let [field, meta] = useField(props.name);
    let formik = useFormikContext();
    //let formContext = React.useContext(FormContext);
    let id = props.id ?? field.name;
    useEffect(() => {
        setButtonBorderRadius(getButtonBorderRadius());
    }, []);
    return (<>
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text><FontAwesomeIcon color="#216aeb" icon={faLock}></FontAwesomeIcon></InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Label htmlFor={id} srOnly>{props.label}</Form.Label>
            <Form.Control type={showPassword ? 'text' : 'password'} name={field.name}
                onKeyDown={props.onKeyDown}
                value={field.value ?? ''}
                onChange={field.onChange} onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    formik.setFieldValue(field.name, e.target.value.trim());
                }} placeholder={props.placeholder}
                isInvalid={meta.touched && !!meta.error} id={id} readOnly={props.readOnly}>
            </Form.Control>
            <InputGroup.Append>
                <Button onClick={() => setShowPassword(!showPassword)} variant="outline-primary"
                    style={{
                        /*dado que el InputGroup.Append no es el ultimo elemento del InputGroup hay que corregir el border-radius del boton manualmente*/
                        borderTopRightRadius: buttonBorderRadius?.topRight,
                        borderBottomRightRadius: buttonBorderRadius?.bottomRight
                    }}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
                </Button>
            </InputGroup.Append>
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        </InputGroup>
    </>);
}

export function MyFormCheck(props: {
    id?: string, name: string, label?: string, labelOnLeft?: boolean,
    onCheckedChange?: (checked: boolean) => void,
    disabled?: boolean, readOnly?: boolean, className?: string
}) {
    let [field, meta] = useField(props.name);
    let id = props.id ?? field.name;
    let className = props.className ?? '';
    if (props.labelOnLeft) {
        className += ' custom-check-right';
    }
    return (
        <Form.Check name={field.name} id={id} label={props.label} checked={field.value ?? false}
            className={className} onChange={(e: any) => {
                field.onChange(e);
                props.onCheckedChange?.call(undefined, e.target.checked);
            }} onBlur={field.onBlur} custom value="true"
            disabled={props.disabled}
            isInvalid={meta.touched && !!meta.error} feedback={meta.error} readOnly={props.readOnly}>
        </Form.Check >);
}

export function MyFormRadio(props: {
    id: string, name: string, label?: string, labelOnLeft?: boolean,
    onCheckedChange?: (checked: boolean) => void,
    disabled?: boolean, readOnly?: boolean, value: string | number, inline?: boolean
}) {
    let [field, meta] = useField(props.name);
    return (
        <Form.Check type="radio" name={field.name} id={props.id} label={props.label}
            checked={field.value === `${props.value}`} inline={props.inline}
            className={props.labelOnLeft ? 'custom-check-right' : ''} onChange={(e: any) => {
                field.onChange(e);
                props.onCheckedChange?.call(undefined, e.target.checked);
            }} onBlur={field.onBlur} custom value={`${props.value}`}
            disabled={props.disabled} readOnly={props.readOnly}
            isInvalid={meta.touched && !!meta.error} feedback={meta.error}>
        </Form.Check >);
}

// export type SelectOption = { value: string, label: string | null | undefined };
// export type OnValueChangeArgument = string | readonly string[] | null | undefined;

// function typeGuardSelectOption(option: SelectOption | readonly SelectOption[] | null | undefined): option is readonly SelectOption[] {
//     return Array.isArray(option);
// }
// function typeGuardVal(val: AllowedSelectValues): val is string[] {
//     return Array.isArray(val);
// }

// const ErrorMessage = styled.div.attrs(props => ({ className: 'invalid-feedback' }))`
//     display:block;
// `;

// function getErrorBorderColor() {
//     let div = document.createElement('div');
//     div.className = 'form-control is-invalid d-none';
//     document?.querySelector('body')?.append(div);
//     let style = window.getComputedStyle(div);
//     let errorBorderColor = style.getPropertyValue('border-color') || style.getPropertyValue('border-top-color');
//     div.parentNode?.removeChild(div);
//     return errorBorderColor;
// }

function getButtonBorderRadius() {
    let div = document.createElement('button');
    div.className = 'btn d-none';
    document?.querySelector('body')?.append(div);
    let style = window.getComputedStyle(div);
    let topRight = style.getPropertyValue('border-top-right-radius');
    let bottomRight = style.getPropertyValue('border-bottom-right-radius');
    div.parentNode?.removeChild(div);
    return { topRight: topRight, bottomRight: bottomRight };
}

// export interface MySelectRef {
//     getCurrentOptions: () => SelectOption | readonly SelectOption[] | null | undefined
// }
// type MySelectProps = {
//     id?: string, name: string, autoFocus?: boolean,
//     label: string, hideLabel?: boolean, options: Array<SelectOption> | (() => Promise<Array<SelectOption>>),
//     isMulti?: boolean, isClearable?: boolean, isDisabled?: boolean,
//     getOptionLabel?: (option: SelectOption) => string,
//     onOptionChange?: (option: SelectOption | readonly SelectOption[] | null | undefined) => void,
//     onValueChange?: (option: OnValueChangeArgument) => void,
// };

// export const MySelect = React.forwardRef((props: MySelectProps, ref: any) => {
//     let context = useFormikContext();
//     let [field, meta] = useField(props.name);
//     let optionsInProps = useRef(props.options);
//     let [options, updateOptions] = useState(typeof props.options === 'function' ? [] : props.options);
//     let [cargando, updateCargando] = useState(typeof props.options === 'function' ? true : false);
//     let errorBorderColor = useRef('');
//     let selectedOptions = useRef<SelectOption | readonly SelectOption[] | null>(null);
//     useEffect(() => {
//         errorBorderColor.current = getErrorBorderColor();
//         if (typeof optionsInProps.current === 'function') {
//             optionsInProps.current().then(result => {
//                 updateOptions(result);
//                 updateCargando(false);
//             }).catch(error => {
//                 updateOptions([]);
//                 updateCargando(false);
//             });
//         }
//     }, []);
//     useEffect(() => {
//         if (Array.isArray(props.options) && typeof optionsInProps.current !== 'function') {
//             updateOptions(props.options);
//         }
//     }, [props.options]);
//     useImperativeHandle(ref, () => ({
//         getCurrentOptions: () => selectedOptions.current
//     }));
//     function getValue() {
//         if (props.isMulti) {
//             return options.filter(option => typeGuardVal(field.value) ? field.value.includes(option.value) :
//                 option.value === field.value?.toString());
//         } else {
//             if (field.value === null || field.value === undefined ||
//                 (typeof (field.value) === 'string' && isNullOrWhiteSpace(field.value))) {
//                 return null;
//             } else {
//                 return options.find(option => typeGuardVal(field.value) ? field.value.includes(option.value)
//                     : option.value === field.value.toString()) ?? null;
//             }
//         }
//     }
//     return (
//         <>
//             <Form.Label htmlFor={props.id} srOnly={props.hideLabel}>{props.label}</Form.Label>
//             <BlockUi blocking={cargando} style={{ width: '100%', display: 'block' }}>
//                 <WindowedSelect autoFocus={props.autoFocus} isDisabled={props.isDisabled} options={options} onBlur={() => context.setFieldTouched(field.name)} isMulti={props.isMulti} name={props.name}
//                     value={getValue()} getOptionLabel={props.getOptionLabel} id={props.id} placeholder="Seleccionar..."
//                     onChange={(option: SelectOption | readonly SelectOption[] | null | undefined) => {
//                         selectedOptions.current = option ?? null;
//                         context.setFieldTouched(field.name);
//                         if (typeGuardSelectOption(option)) {
//                             if (typeGuardVal(field.value)) {
//                                 context.setFieldValue(field.name, option.map((item: SelectOption) => item.value));
//                             } else if (option.length > 0) {
//                                 context.setFieldValue(field.name, option[0].value ?? null);
//                             } else {
//                                 context.setFieldValue(field.name, null);
//                             }
//                             props.onValueChange?.call(undefined, option.map((item: SelectOption) => item.value));
//                         } else {
//                             if (typeGuardVal(field.value)) {
//                                 context.setFieldValue(field.name, option ? [option.value] : []);
//                             } else {
//                                 context.setFieldValue(field.name, option?.value ?? null);
//                             }
//                             props.onValueChange?.call(undefined, option?.value ?? null);
//                         }
//                         props.onOptionChange?.call(undefined, option);
//                     }} isClearable={props.isClearable} styles={{
//                         control: (provided: any, state: any) => {
//                             return meta.touched && meta.error ? { ...provided, borderColor: errorBorderColor.current } : provided
//                         }, menu: (provided: any, state: any) => ({ ...provided, zIndex: 2 }),
//                     }}>
//                 </WindowedSelect>
//             </BlockUi>
//             { meta.touched && meta.error && (<ErrorMessage>{meta.error}</ErrorMessage>)}
//         </>
//     )
// });

// export type MyAsyncSelectRef = {
//     focus: () => void
// }
// export const MyAsyncSelect = React.forwardRef(
//     (props: {
//         id?: string, name: string, label?: string, hideLabel?: boolean,
//         defaultOptions?: Array<SelectOption> | boolean, cacheOptions?: boolean, isMulti?: boolean,
//         isClearable?: boolean,
//         loadOptions: (inputValue: string, callback?:
//             (arg: readonly SelectOption[]) => void) => Promise<SelectOption[]> | void,
//         onValueChange?: (option: OnValueChangeArgument) => void,
//         onKeyDown?: React.KeyboardEventHandler<any>,
//         isDisabled?: boolean
//     }, ref) => {
//         let selectRef = useRef<any>(null);
//         let context = useFormikContext();
//         let [field, meta] = useField(props.name);
//         let errorBorderColor = useRef('');
//         let [currentInputValue, updateCurrentInputValue] = useState('');
//         useEffect(() => {
//             errorBorderColor.current = getErrorBorderColor();
//         }, []);
//         useImperativeHandle(ref, () => ({
//             focus: () => selectRef.current?.focus()
//         }));
//         function getValue(val: AllowedSelectValues) {
//             if (props.isMulti) {
//                 if (typeGuardVal(val)) {
//                     return val.map(item => ({ value: item, label: item }));
//                 } else {
//                     return [{ value: val?.toString(), label: val?.toString() }];
//                 }
//             } else {
//                 if (typeGuardVal(val)) {
//                     return { value: val[0], label: val[0] }
//                 } else {
//                     return { value: val?.toString(), label: val?.toString() };
//                 }
//             }
//         }
//         return (
//             <>
//                 <Form.Label htmlFor={props.id} srOnly={props.hideLabel}>{props.label}</Form.Label>
//                 <AsyncSelect ref={currentRef => {
//                     selectRef.current = currentRef;
//                 }} defaultOptions={props.defaultOptions} cacheOptions={props.cacheOptions}
//                     loadOptions={props.loadOptions} onBlur={() => context.setFieldTouched(field.name)}
//                     isClearable={props.isClearable} placeholder="Seleccionar..."
//                     value={getValue(field.value)} name={props.name} id={props.id}
//                     onKeyDown={props.onKeyDown} onChange={options => {
//                         context.setFieldTouched(field.name);
//                         if (typeGuardSelectOption(option)) {
//                             if (typeGuardVal(field.value)) {
//                                 context.setFieldValue(field.name, option.map((item: SelectOption) => item.value));
//                             } else if (option.length > 0) {
//                                 context.setFieldValue(field.name, option[0].value ?? null);
//                             } else {
//                                 context.setFieldValue(field.name, null);
//                             }
//                             props.onValueChange?.call(undefined, option.map((item: SelectOption) => item.value));
//                         } else {
//                             if (typeGuardVal(field.value)) {
//                                 context.setFieldValue(field.name, option ? [option.value] : []);
//                             } else {
//                                 context.setFieldValue(field.name, option?.value ?? null);
//                             }
//                             props.onValueChange?.call(undefined, option?.value ?? null);
//                         }
//                     }} styles={{
//                         control: (provided, state) => {
//                             return meta.touched && meta.error ? { ...provided, borderColor: errorBorderColor.current } : provided
//                         }, menu: (provided, state) => ({ ...provided, zIndex: 2 }),
//                         container: (provided, state) => ({ ...provided, display: 'block', width: '100%' })
//                     }}
//                     onMenuOpen={() => {
//                         if (typeGuardVal(field.value)) {
//                             updateCurrentInputValue(field.value[0] ?? '');
//                         } else {
//                             updateCurrentInputValue(`${field.value ?? ''}`);
//                         }
//                     }} onInputChange={(inputValue, { action }) => {
//                         updateCurrentInputValue(inputValue ?? '');
//                     }} inputValue={currentInputValue} isDisabled={props.isDisabled}>
//                 </AsyncSelect>
//                 {meta.touched && meta.error && (<ErrorMessage>{meta.error}</ErrorMessage>)}
//             </>
//         )
//     });

// export const MyAsyncCreatableSelect = React.forwardRef(
//     (props: {
//         id?: string, name: string, label?: string, hideLabel?: boolean,
//         defaultOptions?: Array<SelectOption> | boolean, cacheOptions?: boolean, isMulti?: boolean,
//         isClearable?: boolean, isDisabled?: boolean,
//         loadOptions: (inputValue: string, callback?:
//             (arg: readonly SelectOption[]) => void) => Promise<SelectOption[]> | void,
//         onValueChange?: (option: OnValueChangeArgument) => void,
//         onKeyDown?: React.KeyboardEventHandler<any>,
//     }, ref: any) => {
//         let selectRef = useRef<any>(null);
//         let context = useFormikContext();
//         let [field, meta] = useField(props.name);
//         let errorBorderColor = useRef('');
//         let [currentInputValue, updateCurrentInputValue] = useState('');
//         useEffect(() => {
//             errorBorderColor.current = getErrorBorderColor();
//         }, []);
//         useImperativeHandle(ref, () => ({
//             focus: () => selectRef.current?.focus()
//         }));
//         function getValue(val: AllowedSelectValues) {
//             if (props.isMulti) {
//                 if (typeGuardVal(val)) {
//                     return val.map(item => ({ value: item, label: item }));
//                 } else {
//                     return [{ value: val?.toString(), label: val?.toString() }];
//                 }
//             } else {
//                 if (typeGuardVal(val)) {
//                     return { value: val[0], label: val[0] }
//                 } else {
//                     return { value: val?.toString(), label: val?.toString() };
//                 }
//             }
//         }
//         return (
//             <>
//                 <Form.Label htmlFor={props.id} srOnly={props.hideLabel}>{props.label}</Form.Label>
//                 <AsyncCreatableSelect ref={currentRef => {
//                     selectRef.current = currentRef;
//                 }} defaultOptions={props.defaultOptions} cacheOptions={props.cacheOptions}
//                     loadOptions={props.loadOptions} onBlur={() => context.setFieldTouched(field.name)}
//                     isClearable={props.isClearable} isDisabled={props.isDisabled} placeholder="Seleccionar..."
//                     value={getValue(field.value)} name={props.name} id={props.id}
//                     onKeyDown={props.onKeyDown} onChange={(option: SelectOption | readonly SelectOption[] | null | undefined) => {
//                         context.setFieldTouched(field.name);
//                         if (typeGuardSelectOption(option)) {
//                             if (typeGuardVal(field.value)) {
//                                 context.setFieldValue(field.name, option.map((item: SelectOption) => item.value));
//                             } else if (option.length > 0) {
//                                 context.setFieldValue(field.name, option[0].value ?? null);
//                             } else {
//                                 context.setFieldValue(field.name, null);
//                             }
//                             props.onValueChange?.call(undefined, option.map((item: SelectOption) => item.value));
//                         } else {
//                             if (typeGuardVal(field.value)) {
//                                 context.setFieldValue(field.name, option ? [option.value] : []);
//                             } else {
//                                 context.setFieldValue(field.name, option?.value ?? null);
//                             }
//                             props.onValueChange?.call(undefined, option?.value ?? null);
//                         }
//                     }} styles={{
//                         control: (provided, state) => {
//                             return meta.touched && meta.error ? { ...provided, borderColor: errorBorderColor.current } : provided
//                         }, menu: (provided, state) => ({ ...provided, zIndex: 2 }),
//                         container: (provided, state) => ({ ...provided, display: 'block', width: '100%' })
//                     }}
//                     onMenuOpen={() => {
//                         if (typeGuardVal(field.value)) {
//                             updateCurrentInputValue(field.value[0] ?? '');
//                         } else {
//                             updateCurrentInputValue(`${field.value ?? ''}`);
//                         }
//                     }} onInputChange={(inputValue, { action }) => {
//                         updateCurrentInputValue(inputValue ?? '');
//                     }} inputValue={currentInputValue} createOptionPosition="first"
//                     formatCreateLabel={valor => valor}>
//                 </AsyncCreatableSelect>
//                 {meta.touched && meta.error && (<ErrorMessage>{meta.error}</ErrorMessage>)}
//             </>
//         )
//     });

export const MyForm = connect<{
    blockWhenSubmitting?: boolean, inline?: boolean, blocking?: boolean,
    className?: string, submitEnUltimoElemento?: boolean
}>(props => {
    let formRef = useRef<HTMLFormElement>(null);
    let [autoFocusElement, updateAutoFocusElement] = useState<HTMLElement | null>(null);
    let { blockWhenSubmitting, blocking, inline, formik, children, submitEnUltimoElemento,
        ...otrosProps } = props;
    /* parche para asegurar que todos los campos estén touched al hacer submit aún si
       initialValues esté vacio 
       https://github.com/formium/formik/issues/445#issuecomment-677592750 */
    useEffect(() => {
        if (formik.isSubmitting && !formik.isValidating) {
            for (const path of Object.keys(flatten(formik.errors))) {
                formik.setFieldTouched(path, true, false);
            }
        }
        // eslint-disable-next-line
    }, [formik.errors, formik.isSubmitting, formik.isValidating, formik.setFieldTouched]);
    let bloquearForm = blocking || ((blockWhenSubmitting ?? true) && formik.isSubmitting);
    useEffect(() => {
        if (!bloquearForm) {
            if (autoFocusElement) {
                autoFocusElement.focus();
            } else if (formRef.current) {
                let elementosDeForm = formRef.current.querySelectorAll('input:not([type=submit],[type=hidden]):enabled,select:enabled,textarea:enabled');
                if (elementosDeForm.length > 0) {
                    let elemento = elementosDeForm[0] as HTMLElement;
                    elemento.focus();
                }
            }
        }
    }, [autoFocusElement, bloquearForm]);
    //TODO: ver porque los selectores dan error en Chrome
    function onKeyDownForm(e: React.KeyboardEvent<HTMLElement>) {
        let element = e.target as HTMLElement;
        if (e.key === 'Enter' && element?.matches('input:not([type=submit],[type=hidden]),select')) {
            e.preventDefault();
            e.stopPropagation();
            if (document.activeElement) {
                let inputs = Array.from(e.currentTarget.querySelectorAll('input:not([type=submit],[type=hidden]):enabled,select:enabled,textarea:enabled'));
                inputs = inputs.filter(i => {
                    let input = i as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
                    return input.tabIndex >= 0;
                });
                let indexOfActiveInput = inputs.indexOf(document.activeElement);
                if (indexOfActiveInput > -1) {
                    if (indexOfActiveInput + 1 >= inputs.length && (submitEnUltimoElemento ?? true)) {
                        formik.submitForm();
                    } else {
                        let elemento = inputs[indexOfActiveInput + 1] as HTMLElement;
                        elemento?.focus();
                    }
                }
            }
        }
    }
    return (
        <FormContext.Provider value={{ inline: inline ?? false, setAutoFocusElement: updateAutoFocusElement }}>
            <BlockUi tag="div" keepInView blocking={bloquearForm}>
                <Form noValidate ref={formRef}
                    onKeyDown={onKeyDownForm}
                    onSubmit={e => {
                        let formEvent = e as React.FormEvent<HTMLFormElement>;
                        if (formEvent) {
                            props.formik.handleSubmit(formEvent);
                        } else {
                            console.error('Evento en submit no es FormEvent<HtmlFormElement>');
                        }
                    }}{...otrosProps}>
                    {children}
                </Form>
            </BlockUi>
        </FormContext.Provider>);
});