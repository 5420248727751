import React, { useEffect } from "react";
import BlockUi from "react-block-ui";
import axios, { CancelToken } from "axios";
import { Button, Form, Pagination, Table, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from 'yup';
import { AppContext, SesionPerdidaError } from './App'
import { Formik } from "formik";
import { MyForm, MyFormControl } from "./FormikHooks";
import { DateTime } from "luxon";
import { toFixedDecimal } from "Utilidades";
import { objectEquals } from "object-equals";
import useForceUpdate from "use-force-update";

function cargarDatosGrillaDeArray(array: Array<any>) {
    array = array ?? [];
    return (desde: number, hasta: number) => {
        return Promise.resolve({ cantidadItems: array.length, items: array.slice(desde - 1, hasta) });
    };
}

export enum TipoCampo {
    Number = 'number',
    Date = 'date',
    DateTime = 'datetime',
    Any = 'any'
};

export enum Alineacion {
    Izquierda = 'left',
    Centro = 'center',
    Derecha = 'right'
}

const GrillaSyncContext = React.createContext({
    forceUpdate: () => { }
});

interface GrillaProps {
    mostrarFormCambiarPagina?: boolean,
    seleccionMultiple?: boolean,
    responsiva?: boolean,
    itemsPorPagina?: number,
    cargarDatos: (desde: number, hasta: number, cancelToken: CancelToken) => Promise<{ cantidadItems: number, items: any }>,
    eventoAgregar?: () => void,
    deshabilitarBotonAgregar?: boolean,
    eventoModificar?: (item: any) => void,
    deshabilitarBotonModificar?: boolean,
    eventoEliminar?: (item: any) => void,
    deshabilitarBotonEliminar?: boolean,
    eventoDetalle?: (item: any) => void,
    deshabilitarEventoDetalle?: boolean,
    eventoCambioFoco?: (item: any) => void,
    habilitarFoco?: boolean,
    campos: Array<{
        visible?: boolean,
        plantillaFormato?: (valor: any, item: any) => string,
        tipo?: TipoCampo,
        decimales?: number,
        propiedad: string,
        titulo?: string,
        clave?: boolean,
        alineacion?: Alineacion
    }>
}
export interface GrillaRef {
    setBloqueado: (valor: boolean) => void;
    recargar: () => void;
    cambiarPagina: (pagina: number) => void;
    getClavesSeleccionadas: () => any[];
    borrarClavesSeleccionadas: () => void;
    ponerFoco: (clave: any) => void;
}
const Grilla = React.forwardRef((props: GrillaProps, ref) => {
    let desmontado = React.useRef({ valor: false });
    let { mostrarError } = React.useContext(AppContext);
    let grillaSyncContext = React.useContext(GrillaSyncContext);
    let campos = React.useRef(props.campos);
    let campoClave = React.useRef(props.campos.filter(campo => campo.clave));
    if (campoClave.current.length !== 1) {
        throw new Error('La grilla debe tener un y solo un campo clave');
    }
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cambiarPagina') {
            return {
                ...estado, paginaNueva: accion.pagina, cargando: true,
                contadorCarga: estado.contadorCarga + 1,
            };
        } else if (accion.tipo === 'cargarItems') {
            let nuevoItemConFoco = null;
            if (estado.itemConFoco) {
                let clave = estado.itemConFoco[campoClave.current[0].propiedad];
                nuevoItemConFoco = accion.items?.find((item: any) => item[campoClave.current[0].propiedad] === clave);
            }
            return {
                ...estado, pagina: estado.paginaNueva, items: accion.items ?? [],
                cantidadItems: accion.cantidadItems, cargando: false,
                itemConFoco: nuevoItemConFoco
            };
        } else if (accion.tipo === 'cancelarCarga') {
            return { ...estado, paginaNueva: estado.pagina, cargando: false };
        } else if (accion.tipo === 'recargar') {
            return { ...estado, clavesSeleccionadas: [], cargando: true, contadorCarga: estado.contadorCarga + 1 };
        } else if (accion.tipo === 'setBloqueado') {
            return { ...estado, bloqueado: accion.valor };
        } else if (accion.tipo === 'seleccionarClave') {
            let claves = Array.from(estado.clavesSeleccionadas);
            if (accion.valor !== null && accion.valor !== undefined) {
                claves.push(accion.valor);
            }
            return { ...estado, clavesSeleccionadas: claves };
        } else if (accion.tipo === 'desseleccionarClave') {
            let claves = estado.clavesSeleccionadas.filter((c: any) => c !== accion.valor);
            return { ...estado, clavesSeleccionadas: claves };
        } else if (accion.tipo === 'borrarClavesSeleccionadas') {
            return { ...estado, clavesSeleccionadas: [] };
        } else if (accion.tipo === 'seleccionarTodaLaPagina') {
            let claves = Array.from(estado.clavesSeleccionadas);
            estado.items.forEach((item: any) => {
                let clave = item[campoClave.current[0].propiedad];
                if (clave !== null && clave !== undefined && !claves.includes(clave)) {
                    claves.push(clave);
                }
            });
            return { ...estado, clavesSeleccionadas: claves };
        } else if (accion.tipo === 'ponerFocoItem') {
            return { ...estado, itemConFoco: accion.valor };
        }
    }, {
        paginaNueva: 1,
        pagina: 1,
        items: [],
        cargando: true,
        bloqueado: false,
        cantidadItems: 0,
        contadorCarga: 0,
        clavesSeleccionadas: [],
        itemConFoco: null
    });
    let itemsPorPagina = props.itemsPorPagina === undefined || props.itemsPorPagina <= 0 ? 20 : props.itemsPorPagina;
    let cantidadPaginas = Math.ceil(estado.cantidadItems / itemsPorPagina);
    React.useEffect(() => {
        return () => {
            //eslint-disable-next-line
            desmontado.current.valor = true;
        }
    }, []);
    React.useEffect(() => {
        let cancelTokenSource = axios.CancelToken.source();
        async function llamarCargarDatos() {
            try {
                let resultado = await props.cargarDatos(((estado.paginaNueva - 1) * itemsPorPagina) + 1,
                    estado.paginaNueva * itemsPorPagina, cancelTokenSource.token);
                if (resultado.cantidadItems > 0 && estado.paginaNueva > Math.ceil(resultado.cantidadItems / itemsPorPagina)) {
                    updateEstado({ tipo: 'cambiarPagina', pagina: Math.ceil(resultado.cantidadItems / itemsPorPagina) });
                } else {
                    updateEstado({ tipo: 'cargarItems', items: resultado.items, cantidadItems: resultado.cantidadItems })
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error al cargar grilla', error);
                    if (!desmontado.current.valor) {
                        updateEstado({ tipo: 'cancelarCarga' });
                        if (!(error instanceof SesionPerdidaError)) {
                            mostrarError('Error al cargar grilla');
                        }
                    }
                }
            }
        }
        llamarCargarDatos();
        return () => cancelTokenSource.cancel();
        //eslint-disable-next-line
    }, [estado.paginaNueva, estado.contadorCarga, itemsPorPagina, mostrarError]);
    React.useImperativeHandle(ref, () => ({
        setBloqueado: (valor: boolean) => updateEstado({ tipo: 'setBloqueado', valor: valor }),
        recargar: () => updateEstado({ tipo: 'recargar' }),
        cambiarPagina: (pagina: number) => updateEstado({ tipo: 'cambiarPagina', pagina: pagina }),
        getClavesSeleccionadas: () => estado.clavesSeleccionadas.filter((c: any) => c !== null && c !== undefined),
        borrarClavesSeleccionadas: () => updateEstado({ tipo: 'borrarClavesSeleccionadas' }),
        ponerFoco: (clave: any) => {
            if (props.habilitarFoco) {
                let item = estado.items?.find((item: any) => item[campoClave.current[0].propiedad] === clave);
                if (item) {
                    props.eventoCambioFoco?.call(undefined, item);
                    updateEstado({ tipo: 'ponerFocoItem', valor: item });
                }
            }
        }
    }));
    React.useEffect(() => {
        //si cambia el estado y la grilla está dentro de un GrillaSync, forzar el rerender de GrillaSync para actualizar el ref que devuelve
        grillaSyncContext?.forceUpdate();
        //eslint-disable-next-line
    }, [estado]);
    //let claseTabla = props.responsiva ? 'text-nowrap' : 'clamp';
    let claseTabla = 'text-nowrap middle-vertical-align';
    if (props.seleccionMultiple) {
        claseTabla += ' tablaConSeleccion';
    }
    let strElementosSeleccionados = '';
    if (props.seleccionMultiple) {
        if (estado.clavesSeleccionadas.length === 1) {
            strElementosSeleccionados = '1 elemento seleccionado';
        } else if (estado.clavesSeleccionadas.length >= 2) {
            strElementosSeleccionados = estado.clavesSeleccionadas.length + ' elementos seleccionados';
        }
    }
    return (<BlockUi keepInView blocking={(estado.cargando || estado.bloqueado) ? true : undefined}>
        {props.eventoAgregar && <Button className="mb-2" disabled={props.deshabilitarBotonAgregar} onClick={() => props.eventoAgregar?.call(undefined)}>
            <FontAwesomeIcon icon={faPlus} />
            <span>Agregar</span>
        </Button>}
        {props.seleccionMultiple && (<>
            <Button className="mb-2 mr-2 font-weight-normal" variant="secondary" onClick={() => updateEstado({ tipo: 'seleccionarTodaLaPagina' })}>
                Seleccionar Todos
            </Button>
            <Button className="mb-2 mr-2 font-weight-normal" variant="secondary" onClick={() => updateEstado({ tipo: 'borrarClavesSeleccionadas' })}>
                Borrar Selección
            </Button>
            <p>{strElementosSeleccionados}</p>
        </>)}
        <Table bordered striped hover={props.eventoDetalle !== undefined || props.habilitarFoco}
            responsive className={claseTabla} size="sm">
            <thead>
                <tr>
                    {props.seleccionMultiple && (<th></th>)}
                    {campos.current.filter(campo => campo.visible ?? true).map(campo => {
                        let tdClass = '';
                        if (campo.alineacion === Alineacion.Izquierda) {
                            tdClass = 'text-left';
                        } else if (campo.alineacion === Alineacion.Centro) {
                            tdClass = 'text-center';
                        } else if (campo.alineacion === Alineacion.Derecha) {
                            tdClass = 'text-right';
                        } else if (campo.tipo === TipoCampo.Number || campo.tipo === TipoCampo.Date || campo.tipo === TipoCampo.DateTime) {
                            tdClass = 'text-right';
                        }
                        return (<th key={campo.propiedad} className={tdClass}>{campo.titulo ?? ''}</th>);
                    })}
                    {(props.eventoModificar || props.eventoEliminar) && (<th></th>)}
                </tr>
            </thead>
            <tbody>
                {estado.items.map((item: any) => {
                    let trClass = '';
                    if (props.eventoDetalle && !props.deshabilitarEventoDetalle) {
                        trClass = 'cursor-pointer';
                    } else if (props.habilitarFoco) {
                        trClass = 'cursor-pointer';
                        if (estado.itemConFoco === item) {
                            trClass += ' table-primary';
                        }
                    }
                    return (<tr key={item[campoClave.current[0].propiedad]} className={trClass}
                        onClick={e => {
                            let element = e.target as HTMLElement;
                            //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                            if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                                if (props.eventoDetalle && !props.deshabilitarEventoDetalle) {
                                    props.eventoDetalle(item);
                                } else if (props.habilitarFoco) {
                                    props.eventoCambioFoco?.call(undefined, item);
                                    updateEstado({ tipo: 'ponerFocoItem', valor: item });
                                }
                            }
                        }}>
                        {props.seleccionMultiple && (<td>
                            {/* Se usa un label vacio en Form.Check para que se muestre el checkbox */}
                            <Form.Check custom id={`check-${item[campoClave.current[0].propiedad]}`} label=" " aria-label="Seleccionar"
                                checked={estado.clavesSeleccionadas.includes(item[campoClave.current[0].propiedad])} onChange={(e: any) => {
                                    if (e.target.checked) {
                                        updateEstado({ tipo: 'seleccionarClave', valor: item[campoClave.current[0].propiedad] });
                                    } else {
                                        updateEstado({ tipo: 'desseleccionarClave', valor: item[campoClave.current[0].propiedad] });
                                    }
                                }}></Form.Check>
                        </td>)}
                        {campos.current.filter(campo => campo.visible ?? true).map(campo => {
                            let texto = '';
                            let valor = item[campo.propiedad];
                            if (campo.plantillaFormato) {
                                texto = campo.plantillaFormato(item[campo.propiedad], item);
                            } else if (valor !== null && valor !== undefined) {
                                if (campo.tipo === TipoCampo.Date) {
                                    texto = DateTime.fromISO(valor).toLocaleString({
                                        ...DateTime.DATE_SHORT,
                                        'day': '2-digit', 'month': '2-digit'
                                    });
                                } else if (campo.tipo === TipoCampo.DateTime) {
                                    texto = DateTime.fromISO(valor).toLocaleString({
                                        ...DateTime.DATETIME_SHORT,
                                        'day': '2-digit', 'month': '2-digit', 'hour': '2-digit', 'minute': '2-digit'
                                    });
                                } else if (campo.tipo === TipoCampo.Number) {
                                    texto = toFixedDecimal(valor, campo.decimales ?? 2);
                                } else {
                                    texto = valor;
                                }
                            }
                            let tdClass = '';
                            if (campo.alineacion === Alineacion.Izquierda) {
                                tdClass = 'text-left';
                            } else if (campo.alineacion === Alineacion.Centro) {
                                tdClass = 'text-center';
                            } else if (campo.alineacion === Alineacion.Derecha) {
                                tdClass = 'text-right';
                            } else if (campo.tipo === TipoCampo.Number || campo.tipo === TipoCampo.Date || campo.tipo === TipoCampo.DateTime) {
                                tdClass = 'text-right';
                            }
                            return (<td key={campo.propiedad} className={tdClass}>{texto}</td>)
                        })}
                        {((props.eventoModificar || props.eventoEliminar)) &&
                            (<td className="control">
                                {props.eventoModificar && (<Button variant="link" onClick={(e) => {
                                    props.eventoModificar?.call(undefined, item);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} disabled={props.deshabilitarBotonModificar}><FontAwesomeIcon icon={faEdit} /></Button>)}
                                {props.eventoEliminar && (<Button variant="link" onClick={(e) => {
                                    props.eventoEliminar?.call(undefined, item);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} disabled={props.deshabilitarBotonEliminar}><FontAwesomeIcon icon={faTrash} /></Button>)}
                            </td>)}
                    </tr>)
                })}
            </tbody>
        </Table>
        <div className="d-flex justify-content-center">
            <Pagination>
                <Pagination.First disabled={estado.pagina === 1} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: 1 })}></Pagination.First>
                <Pagination.Prev disabled={estado.pagina === 1} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: estado.pagina - 1 })} ></Pagination.Prev>
                <Pagination.Item active={estado.pagina === 1} key={1} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: 1 })} > 1</Pagination.Item>
                {cantidadPaginas <= 6 ? (<>
                    {Array.from({ length: cantidadPaginas - 1 }, (value: any, index: number) => {
                        let pageNum = index + 2;
                        return (<Pagination.Item active={estado.pagina === pageNum} key={pageNum} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: pageNum })} >{pageNum}</Pagination.Item>);
                    })}
                </>) : (<>
                    <Pagination.Item active={estado.pagina === 2} key={2} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: 2 })} > 2</Pagination.Item>
                    <Pagination.Item active={estado.pagina === 3} key={3} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: 3 })} > 3</Pagination.Item>
                    {estado.pagina > 4 && (<Pagination.Ellipsis></Pagination.Ellipsis>)}
                    {estado.pagina > 3 && estado.pagina < cantidadPaginas - 2 && (< Pagination.Item active key={estado.pagina} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: estado.pagina })}> {estado.pagina}</Pagination.Item>)}
                    {estado.pagina < cantidadPaginas - 3 && (<Pagination.Ellipsis></Pagination.Ellipsis>)}
                    <Pagination.Item active={estado.pagina === cantidadPaginas - 2} key={cantidadPaginas - 2} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: cantidadPaginas - 2 })} >{cantidadPaginas - 2}</Pagination.Item>
                    <Pagination.Item active={estado.pagina === cantidadPaginas - 1} key={cantidadPaginas - 1} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: cantidadPaginas - 1 })} >{cantidadPaginas - 1}</Pagination.Item>
                    <Pagination.Item active={estado.pagina === cantidadPaginas} key={cantidadPaginas} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: cantidadPaginas })}> {cantidadPaginas}</Pagination.Item>
                </>)
                }
                <Pagination.Next disabled={estado.pagina === cantidadPaginas} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: estado.pagina + 1 })}></Pagination.Next>
                <Pagination.Last disabled={estado.pagina === cantidadPaginas} onClick={() => updateEstado({ tipo: 'cambiarPagina', pagina: cantidadPaginas })}></Pagination.Last>
            </Pagination>
        </div>
        {(props.mostrarFormCambiarPagina ?? true) ? (<div className="d-flex justify-content-center">
            <Formik initialValues={{ pagina: 1 }}
                validationSchema={Yup.object({
                    pagina: Yup.number().nullable().required('Debe ingresar un numero').integer('Debe ingresar un numero entero')
                        .min(1, 'Debe ingresar un numero mayor a cero').max(cantidadPaginas, `Solo hay ${cantidadPaginas} paginas`)
                })} onSubmit={(values: { pagina: number }) => updateEstado({ tipo: 'cambiarPagina', pagina: values.pagina })}>
                <MyForm inline blockWhenSubmitting={false} className="mb-2">
                    <Form.Row>
                        <Form.Group as={Col}>
                            <MyFormControl placeholder="Página" type="number" label="Página" name="pagina" hideLabel></MyFormControl>
                        </Form.Group>
                        <div>
                            <Button type="submit">Cambiar página</Button>
                        </div>
                    </Form.Row>
                </MyForm>
            </Formik>
        </div>) : (<></>)}
    </BlockUi >)
});

interface GrillaSyncProps {
    mostrarFormCambiarPagina?: boolean,
    seleccionMultiple?: boolean,
    responsiva?: boolean,
    itemsPorPagina?: number,
    datos: Array<any>,
    eventoAgregar?: () => void,
    deshabilitarBotonAgregar?: boolean,
    eventoModificar?: (item: any) => void,
    deshabilitarBotonModificar?: boolean,
    eventoEliminar?: (item: any) => void,
    deshabilitarBotonEliminar?: boolean,
    eventoDetalle?: (item: any) => void,
    deshabilitarEventoDetalle?: boolean,
    eventoCambioFoco?: (item: any) => void,
    habilitarFoco?: boolean,
    campos: Array<{
        visible?: boolean,
        plantillaFormato?: (valor: any, item: any) => string,
        tipo?: TipoCampo,
        decimales?: number,
        propiedad: string,
        titulo?: string,
        clave?: boolean,
        alineacion?: Alineacion
    }>
}

export const GrillaSync = React.forwardRef((props: GrillaSyncProps, ref: any) => {
    let forceUpdate = useForceUpdate();
    let { datos, ...otrosProps } = props;
    let grillaRef = React.useRef<GrillaRef>(null);
    let datosOld = React.useRef({ valor: [] as any[] });
    useEffect(() => {
        if (!objectEquals(datos, datosOld.current.valor)) {
            datosOld.current.valor = datos;
            grillaRef.current?.recargar();
        }
    }, [datos]);
    React.useImperativeHandle(ref, () => {
        return grillaRef.current;
    });
    let contextValue = React.useMemo(() => ({ forceUpdate: forceUpdate }), [forceUpdate]);
    return <GrillaSyncContext.Provider value={contextValue}>
        <Grilla ref={grillaRef} cargarDatos={cargarDatosGrillaDeArray(datos)} {...otrosProps} />
    </GrillaSyncContext.Provider>
});

export default Grilla;