// import { DateTime } from "luxon";

export default class RateLimit {
    private maxRPS: number;
    private lastTime: number;
    private pending: any[];
    private callback = () => {
        this.lastTime = Date.now();
        //console.log(DateTime.local().toISO());
        let item = this.pending.shift();
        if (item) {
            item();
            if (this.pending.length > 0) {
                setTimeout(this.callback, Math.ceil(1000 / this.maxRPS));
            }
        }
    }
    constructor(maxRPS: number) {
        this.maxRPS = maxRPS;
        this.lastTime = Date.now();
        this.pending = [];
    }

    delayConfig = (config: any) => {
        let now = Date.now();
        if (now - this.lastTime > 1000 / this.maxRPS && this.pending.length === 0) {
            this.lastTime = now;
            //console.log(DateTime.local().toISO());
            return Promise.resolve(config);
        } else {
            return new Promise((resolve, reject) => {
                this.pending.push(() => resolve(config));
                if (this.pending.length === 1) {
                    setTimeout(this.callback, Math.ceil(this.lastTime + (1000 / this.maxRPS) - now));
                }
            });
        }
    }
}