import { UserInfo } from "App";
import { Decimal } from "decimal.js";
import { useRef, useEffect } from "react";
import axios from "axios";

export function isInRole(userInfo: UserInfo, role: string) {
    if (userInfo?.claims === null || userInfo?.claims === undefined) {
        return false;
    }
    if (Array.isArray(userInfo.claims?.role)) {
        return userInfo.claims.role.includes(role);
    } else {
        return userInfo.claims.role === role;
    }
}


export function isNullOrWhiteSpace(str: string | null | undefined) {
    return str === null || str === undefined || `${str}`.match(/^\s*$/) !== null;
}

export function spliceString(str: string, offset: number, text: string, removeCount: number = 0) {
    let calculatedOffset = offset < 0 ? str.length + offset : offset;
    return str.substring(0, calculatedOffset) + text + str.substring(calculatedOffset + removeCount);
}

export function toFixedDecimal(valor: number | string | Decimal | null | undefined, digitos: number) {
    if (valor === null || valor === undefined) {
        return '';
    } else if (typeof valor === 'string' && valor.match(/^\s*$/) !== null) { //ver si la cadena está vacía
        return '';
    }
    try {
        return new Decimal(valor).toFixed(digitos);
    } catch (error) {
        return '';
    }
}

export function useCancel() {
    const cancelTokenSource = useRef(axios.CancelToken.source());
    const desmontado = useRef({ valor: false });
    useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
            //eslint-disable-next-line
            desmontado.current.valor = true;
        }
    }, []);
    return {
        getCancelToken: () => cancelTokenSource.current.token,
        cancelCurrentTokens: () => {
            cancelTokenSource.current.cancel();
            cancelTokenSource.current = axios.CancelToken.source();
        },
        isUnmounted: () => desmontado.current.valor,
        isCancel: axios.isCancel
    }
}

export function createQueryString(obj: Record<string, string | number | boolean | null | undefined>) {
    let array = Object.keys(obj).filter(key => obj[key] !== null && obj[key] !== undefined)
        .map(key => key + '=' + encodeURIComponent(obj[key] as string | number | boolean));
    if (array.length === 0) {
        return '';
    } else {
        return '?' + array.join('&');
    }
}
