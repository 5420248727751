import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { isInRole } from 'Utilidades';
import { AppContext, NotAuthorizedComponent } from './App';

const ProtectedRoute: React.FC<RouteProps & {
    allowRoles?: string[],
    blockRoles?: string[],
    // debeTenerEmpresa?: boolean
}> = props => {
    let { userInfo } = React.useContext(AppContext);
    let { allowRoles, blockRoles, ...otrosProps } = { ...props };
    if (!userInfo.sesionIniciada) {
        let redirectComponent = () => <Redirect to="/login"></Redirect>;
        return <Route {...otrosProps} component={redirectComponent} render={undefined}></Route>
    }
    let autorizado = false;
    if (allowRoles && allowRoles.length > 0) {
        for (const rol of allowRoles) {
            if (isInRole(userInfo, rol)) {
                autorizado = true;
            }
        }
    } else {
        autorizado = true;
    }
    if (blockRoles && blockRoles.length > 0) {
        for (const rol of blockRoles) {
            if (isInRole(userInfo, rol)) {
                autorizado = false;
            }
        }
    }
    if (autorizado) {
        // if (debeTenerEmpresa && !userInfo.empresaActual) {
        //     let redirectComponent = () => <Redirect to="/selectEmpresa"></Redirect>;
        //     return <Route {...otrosProps} component={redirectComponent} render={undefined}></Route>
        // } 
        return <Route {...otrosProps}></Route>
    } else {
        return <Route {...otrosProps}>
            <NotAuthorizedComponent></NotAuthorizedComponent>
        </Route>
    }
};

export const OnlyAnonymousRoute: React.FC<RouteProps> = props => {
    let { userInfo } = React.useContext(AppContext);
    let redirectComponent = () => (<Redirect to="/"></Redirect >);
    return userInfo.sesionIniciada ? (<Route {...props} component={redirectComponent} render={undefined}></Route>) :
        (<Route {...props}></Route>);
}
export default ProtectedRoute;